import apiClient from '@/services/API'

export default {

  getMembership () {
    return apiClient().get('/membership/')
  },

  payWithStripe (data) {
    return apiClient().post('/pay-with-stripe/', data)
  },

 getStripeSession (data) {
    return apiClient().post('/stripesession/', data)
  },

  payWithBillogram (data) {
    return apiClient().post('/pay-with-billogram/', data)
  },
//   getFilters() {
//     return apiClient().get('/filters/')
//   },
//   updateFilters(filters) {
//     return apiClient().post('/filters', filters)
//   },
//   updateMe(user) {
//     return apiClient().put('/users/me', user)
//   }
}
