<template>
  <main id="page-wrap" class="cart">
    <div v-show="user.active_membership" class="grid-container">
      <div class="grid-x grid-margin-x align-center">
        <div class="cell small-12 medium-6">
          <h1>Ditt medlemskap är aktivt! 😊</h1>
          <p>Du kan nu anmäla intresse (eller ointresse) för alla objekt på sajten. Gå till "Hitta byte" för att börja bläddra bland bostäderna. Ju fler bostäder du tackar ja eller nej till desto bättre blir vi på att förstå vad du söker.</p>
          <h2>När du får en matchning</h2>
          <p>När du får en matchning kommer dessa att synas under "mina matchningar". Då får du även tillgång till kontaktuppgifterna till övriga parter i bytet.</p>
          <p>Lycka till!</p>
        </div>
      </div>
    </div>
    <div v-show="!user.active_membership" class="grid-container">
      <div class="grid-x grid-margin-x align-center">
        <div v-if="false" class="cell small-12 medium-4">
          <div sticky-container class="sticky-container create-sidebar show-for-medium">
            <div
              v-sticky
              sticky-offset="offset"
              sticky-side="top"
              class="sticky-inner">
              <aside class="sidebar-wrap">
                <div class="sidebar-content">
                  <section class="todo-box">
                    <ul>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25">
                          <path
                            fill="#64BCB2"
                            fill-rule="nonzero"
                            d="M12.5 25C5.59644063 25 0 19.4035594 0 12.5 0 5.59644063 5.59644063 0 12.5 0 19.4035594 0 25 5.59644063 25 12.5 25 19.4035594 19.4035594 25 12.5 25zm0-3c5.2467051 0 9.5-4.2532949 9.5-9.5C22 7.25329488 17.7467051 3 12.5 3 7.25329488 3 3 7.25329488 3 12.5c0 5.2467051 4.25329488 9.5 9.5 9.5zm-1.2573593-8.9289322l3.8890873-3.88908728c.5857864-.58578644 1.5355339-.58578644 2.1213203 0 .5857865.58578643.5857865 1.53553388 0 2.12132038l-4.9497474 4.9497474c-.5857865.5857865-1.5355339.5857865-2.1213204 0L8.06066017 14.131728c-.58578644-.5857865-.58578644-1.5355339 0-2.1213204.58578644-.5857864 1.53553391-.5857864 2.12132033 0l1.0606602 1.0606602z" />
                        </svg>
                        <span>Ingen&nbsp;bindningstid</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25">
                          <path
                            fill="#64BCB2"
                            fill-rule="nonzero"
                            d="M12.5 25C5.59644063 25 0 19.4035594 0 12.5 0 5.59644063 5.59644063 0 12.5 0 19.4035594 0 25 5.59644063 25 12.5 25 19.4035594 19.4035594 25 12.5 25zm0-3c5.2467051 0 9.5-4.2532949 9.5-9.5C22 7.25329488 17.7467051 3 12.5 3 7.25329488 3 3 7.25329488 3 12.5c0 5.2467051 4.25329488 9.5 9.5 9.5zm-1.2573593-8.9289322l3.8890873-3.88908728c.5857864-.58578644 1.5355339-.58578644 2.1213203 0 .5857865.58578643.5857865 1.53553388 0 2.12132038l-4.9497474 4.9497474c-.5857865.5857865-1.5355339.5857865-2.1213204 0L8.06066017 14.131728c-.58578644-.5857865-.58578644-1.5355339 0-2.1213204.58578644-.5857864 1.53553391-.5857864 2.12132033 0l1.0606602 1.0606602z" />
                        </svg>
                        <span>Trygg&nbsp;betalning&nbsp;med&nbsp;faktura</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25">
                          <path
                            fill="#64BCB2"
                            fill-rule="nonzero"
                            d="M12.5 25C5.59644063 25 0 19.4035594 0 12.5 0 5.59644063 5.59644063 0 12.5 0 19.4035594 0 25 5.59644063 25 12.5 25 19.4035594 19.4035594 25 12.5 25zm0-3c5.2467051 0 9.5-4.2532949 9.5-9.5C22 7.25329488 17.7467051 3 12.5 3 7.25329488 3 3 7.25329488 3 12.5c0 5.2467051 4.25329488 9.5 9.5 9.5zm-1.2573593-8.9289322l3.8890873-3.88908728c.5857864-.58578644 1.5355339-.58578644 2.1213203 0 .5857865.58578643.5857865 1.53553388 0 2.12132038l-4.9497474 4.9497474c-.5857865.5857865-1.5355339.5857865-2.1213204 0L8.06066017 14.131728c-.58578644-.5857865-.58578644-1.5355339 0-2.1213204.58578644-.5857864 1.53553391-.5857864 2.12132033 0l1.0606602 1.0606602z" />
                        </svg>
                        <span>Tusentals&nbsp;annonser</span>
                      </li>
                    </ul>
                  </section>
                </div>
              </aside>
            </div>
          </div>
        </div>

        <div class="cell small-12 medium-8">
          <div class="grid-container">
            <CheckoutForm />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CheckoutForm from '@/components/CheckoutForm'
import { mapGetters } from 'vuex'

export default {
  components: {
    CheckoutForm
  },
  computed: {
    ...mapGetters(['user']),
  },
  data () {
    return {
      offset: {
        top: 114,
        bottom: 100
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h1, h2, p{
  margin-bottom:20px;
}</style>
